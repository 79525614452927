import { Web3Provider } from '@ethersproject/providers'
import { ChainID, ChainType } from '@harmony-js/utils'

	const mainnet = {
		chainId: ChainID.HmyMainnet,
		chainType: ChainType.Harmony,
		url: 'https://api.s0.t.hmny.io',
		networkId: '1666600000'
	}

	const testnet = {
		chainId: ChainID.HmyTestnet,
		chainType: ChainType.Harmony,
		url: 'https://api.s0.b.hmny.io',
		networkId: '1666700000'
	}
	
export const getProvider = () => { return process.env.REACT_APP_NODE_ENV === 'production' ? mainnet : testnet }

export const getLibraryProvider = (provider) => {

		const library = new Web3Provider(provider);
		library.pollingInterval = 12000;

	return library;
};