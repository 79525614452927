import React, { createContext, useContext, useState, useCallback } from 'react'
import { Harmony } from '@harmony-js/core'
import { toBech32 } from '@harmony-js/crypto'
import { isBech32Address, fromWei, hexToNumber, Units, Unit } from '@harmony-js/utils'
import token from '@fuzzbet/abi/ERC20.json'
import { getProvider } from '@configs/provider'
import getNodeUrl from '@src/utils/getRpcUrl'
import Web3 from 'web3'
const web3 = new Web3(getNodeUrl())
const contractAddr = process.env.REACT_APP_FUZZTOKEN
const usdsAddr = "0x471f66F75af9238A2FA23bA23862B5957109fB21"
const woneAddr = "0xcf664087a5bb0237a0bad6742852ec6c8d69a27a"

const HamonyProviderContext = {
	hmy: null,
	balance: "0",
	fuzzBalance: "0",
	fetchBalance: (account) => Promise(null),
	resetBalance: () => null
}

const provider = getProvider()
const hmy = new Harmony(provider.url, { chainId: provider.chainId, chainType: provider.chainType })

const HarmonyContext = createContext(HamonyProviderContext)

const useBalance = () => {
	const [balance, setBalance] = useState()
	const [fuzzBalance, setFuzzBalance] = useState()
	const [woneBalance, setWoneBalance] = useState()
	const [usdsBalance, setUsdcBalance] = useState()

	const fetchBalance = useCallback(
		async (account) => {
			const address = isBech32Address(account) ? account : toBech32(account)
			const balance = await hmy.blockchain.getBalance({ address })
			const parsedBalance = fromWei(hexToNumber(balance.result), Units.one)
			const contract = new web3.eth.Contract(token, contractAddr)
			const usdsContract = new web3.eth.Contract(token, usdsAddr)
			const woneContract = new web3.eth.Contract(token, woneAddr)
			const fuzzBalance = await contract.methods.balanceOf(account).call()
			const usdsBalance = await usdsContract.methods.balanceOf(account).call()
			const woneBalance = await woneContract.methods.balanceOf(account).call()
			setBalance(parsedBalance)
			setFuzzBalance(fromWei(fuzzBalance.toString(), Units.one))
			setUsdcBalance(fromWei(usdsBalance.toString(), Units.Mwei))
			setWoneBalance(fromWei(woneBalance.toString(), Units.one))
		},
		[hmy, setBalance, setFuzzBalance, setUsdcBalance]
	);

	const resetBalance = () => {
		setBalance(undefined)
		setFuzzBalance(undefined)
		setWoneBalance(undefined)
		setUsdcBalance(undefined)
	};

	return {
		balance,
		fuzzBalance,
		usdsBalance,
		woneBalance,
		fetchBalance,
		resetBalance
	};
};

export const HarmonyProvider = ({ children }) => {
	const contextBalance = useBalance()

	const value = {
		hmy,
		...contextBalance
	}

	return <HarmonyContext.Provider value={value}>{children}</HarmonyContext.Provider>;
};

export const useHarmony = () => {
	const context = useContext(HarmonyContext);
	if (!context) {
		throw 'No Harmony provider';
	}
	return context;
};